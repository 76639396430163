
import { defineComponent, inject, onMounted, reactive, toRefs } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import closeIcon from "@/assets/icons/close.svg";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import { useForm } from "vee-validate";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { ContactRepository, RepositoryFactory } from "@/lib/https";
import { isEmptyObject } from "@/lib/utility/common";

export default defineComponent({
  name: "SignersDetailsPopup",
  components: {
    ValidationTextField,
    FlatButton,
    TextButton,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    signers: { type: Array, default: [] as never[] },
    sectionTop: { type: String, required: true },
    nameLabel: { type: String, required: true }
  },
  emits: [
    "update:selectedItems",
    "onClickOutsidePopup",
    "onHide",
    "onAddSigners",
    "backAddSigner",
  ],
  setup(props, { emit }) {
    const state = reactive({
      closeIcon: closeIcon,
      signersDetails: [] as any,
    });
    const isMobile = inject("isMobile");
    const {
    createContact
    } = RepositoryFactory.getRepository<ContactRepository>(ContactRepository);

    const { errors, submitForm } = useForm();
    
    
    const onAddSigners = () => {
      submitForm().then(async() => {
        if (!isEmptyObject(errors.value)) {
          return;
        }
        const addContactRequest = [] as any;
        state.signersDetails.forEach((signer: any) => {
          if(signer.isAddContact) {
            const contactForm = new FormData();
            contactForm.append("firstName", signer.firstName);
            contactForm.append("lastName", signer.lastName);
            contactForm.append("email", signer.email);
            contactForm.append("companyName", signer.companyName);
            addContactRequest.push(createContact(contactForm));                          
          }
        })
        await Promise.all(addContactRequest);
        emit("onAddSigners", state.signersDetails);
      });
    };
    onMounted(() => {
      state.signersDetails = props.signers.map((signer: any) => ({isAddContact: false, ...signer}));
    });

    return { ...toRefs(state), isMobile, onAddSigners, getFilePathFromUrl };
  },
  methods: {
    backAddSigner() {
      this.$emit("backAddSigner", this.signersDetails);
    },

    removeUser(index: number) {
      this.signersDetails.splice(index, 1);
      if (this.signersDetails.length === 0)
        this.$emit("backAddSigner", this.signersDetails);
    },

    clickOutPopup(e: any): void {
      if (e.target.className === "popup-wrapper") {
        this.$emit("onClickOutsidePopup");
      }
    },

    hidePopup(): void {
      this.$emit("onHide");
    },
  },
});

/**
 * @vuese
 * @group Layout
 * Popup layout
 */
