
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  inject,
  watch,
  computed,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TemplateSignerAddPopup from "@/components/popups/templates/TemplateSignerAddPopup.vue";
import DragContainer from "@/components/atomics/drag/DragContainer.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import SignerPlaceholderPopup from "@/components/popups/contract/SignerPlaceholderPopup.vue";
import SignerDetailPopup from "@/components/popups/contract/SignerDetailPopup.vue";
import SignerEditPopup from "@/components/popups/contract/SignerEditPopup.vue";
import EditCancelPopup from "@/components/popups/contract/EditCancelPopup.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

import EditBottomNavBar from "@/components/organisms/EditBottomNavBar.vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { mapMutations, useStore } from "vuex";
import { RepositoryFactory, TemplateRepository, AccountRepository } from "@/lib/https";
import {
  buildContractEditBreadScrumPath,
  getFilePathFromUrl,
} from "@/lib/utility/stringUtil";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import useContract from "./contract";
import { ContractSigner } from "@/models/contracts/ContractSigner";

export default defineComponent({
  name: "ContractSigners",
  components: {
    AfterLoginPageLayout,
    TextButton,
    ToggleCheckbox,
    TemplateSignerAddPopup,
    DragContainer,
    StepProgressBar,
    HeaderTitleBar,
    SignerPlaceholderPopup,
    SignerDetailPopup,
    SignerEditPopup,
    EditCancelPopup,
    BreadScrum,
    FlatButton,
    EditBottomNavBar,
    AvatarCircle,
    Vue3Popper,
  },
  setup() {
    const { t } = useI18n();
    const isMobile = inject("isMobile");
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      isShowAddSignerPopup: false,
      isShowSelectPlaceholderPopup: false,
      isShowSignerDetailPopup: false,
      signerEditIndex: -1,

      users: ref([]),
      selectedUsers: [] as ContractSigner[],
      signers: [] as ContractSigner[],
      progressItems: [
        {
          name: t("template.progressAddFile"),
          event: () => router.push({ name: "Contract", 
                              params: route.params,
           }),
        },
        { name: t("template.progressSigner") },
        { name: t("template.progressVars") },
        { name: t("contract.progressConfirm") },
        { name: t("template.progressDone") },
      ],
      placeholderSignerIndex: -1,
      isEdit: false,
      canDelete: false,
      onlyInformation: false,
      breadScrumPaths: [],
      folderId: "",
      folderName: "",
      contractStatus: "",
    });

    const securitiesInfo = reactive({
      isXIDRequired: false,
      isOnlyPartnerXIDRequired: false,
      is2FARequired: false,
      isSignerTransferAllowed: true,
      needTimeStamp: true,
    });

    const contractInformation = store.getters["template/getTemplateInfo"];
    
    const { removeDraftContract } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const { getFullProfile } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

    const {
      editProgressBarState,
      setError,
      resetState,
      checkingAndNavigation,
      backToFolder,
      saveEditContract,
      saveDraftContract,
      deleteDraftContract,
      showSuccessNotification,
      showErrorNotification,
    } = useContract();

    watch(
      () => securitiesInfo.isXIDRequired,
      (val) => {
        securitiesInfo.isOnlyPartnerXIDRequired =
          val && securitiesInfo.isOnlyPartnerXIDRequired;
      }
    );

    const compressSigners = () => {
      const setOrder = ContractSigner.prototype.setOrder;
      return state.signers.map((signer: ContractSigner, index: number) =>
        setOrder.call(signer, index + 1)
      );
    };

    const storeSignerAndSecurity = async () => {
      const signers = compressSigners();

      await Promise.all([
        store.dispatch("template/setSigners", signers),
        store.dispatch("template/setSecurity", securitiesInfo),
      ]);
    };

    //Save Draft contract
    const onSave = async () => {
      await saveDraftContract(storeSignerAndSecurity);
    };

    // Save edit contract
    const onEdit = async () => {
      await saveEditContract(storeSignerAndSecurity);
    };

    //back and next
    const next = async () => {
      await storeSignerAndSecurity();
      router.push({
        name: "ContractVars",
        query: route.query,
        params: route.params,
      });
    };

    const back = async () => {
      await storeSignerAndSecurity();
      router.push({
        name: "Contract",
        params: route.params,
        query: route.query,
      });
    };

    const isNextButtonEnable = computed(() => {
      if (state.signers.length === 0) return false;
      return state.signers.every(
        (signer: ContractSigner) => !!signer.firstName && !!signer.lastName
      );
    });

    onMounted(async () => {
      checkingAndNavigation(contractInformation);

      const {
        contractSigners,
        isXIDRequired,
        isOnlyPartnerXIDRequired,
        is2FARequired,
        isSignerTransferAllowed,
        needTimeStamp,
        folderId,
        folderName,
        title,
      } = contractInformation;

      setSecurities({
        isXIDRequired,
        isOnlyPartnerXIDRequired,
        is2FARequired,
        isSignerTransferAllowed,
        needTimeStamp,
      });

      state.folderId = folderId;
      state.folderName = folderName;
      state.contractStatus = route.params.status as string;

      state.canDelete = route.params.canDelete === "false" ? false  : true;

      state.isEdit = route.query.isEdit === "true";
      state.onlyInformation = route.query.onlyInformation === "true";
      if (state.isEdit) {
        state.progressItems = editProgressBarState();
        const folderPath = buildContractEditBreadScrumPath(
          folderId ?? "",
          folderName ?? ""
        );
        state.breadScrumPaths = [
          { text: folderName, to: folderPath },
          { text: title, to: "#" },
        ] as never;
      }

      const profilePayload = await getFullProfile();
      setSigners((state.isEdit || contractSigners.length > 0) ? contractSigners : (profilePayload.approvers.length > 0) ? profilePayload.approvers : []);
    });

    const setSigners = (signers: Array<ContractSigner>) => {
      state.signers = signers;
    };

    const setSecurities = (securities: any) => {
      const {
        isXIDRequired = false,
        isOnlyPartnerXIDRequired = false,
        is2FARequired = false,
        isSignerTransferAllowed = false,
        needTimeStamp = false,
      } = securities;
      securitiesInfo.isXIDRequired = isXIDRequired;
      securitiesInfo.isOnlyPartnerXIDRequired = isOnlyPartnerXIDRequired;
      securitiesInfo.is2FARequired = is2FARequired;
      securitiesInfo.isSignerTransferAllowed = isSignerTransferAllowed;
      securitiesInfo.needTimeStamp = needTimeStamp;
    };

    return {
      ...toRefs(state),
      ...toRefs(securitiesInfo),
      isMobile,
      next,
      isNextButtonEnable,
      back,
      onSave,
      deleteDraftContract,
      onEdit,
      resetState,
      contractInformation,
      setError,
      backToFolder,
      getFilePathFromUrl,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isNextButtonEnable) this.setError(1);
    else this.setError(-1);
    if (!to.path.includes("/contract")) {
      this.resetState();
    }
    next();
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    showAddSignerPopup(): void {
      this.selectedUsers = this.signers;
      this.isShowAddSignerPopup = true;
    },
    backAddSigner(payload: any): void {
      this.isShowSignerDetailPopup = false;
      this.selectedUsers = payload;
      this.isShowAddSignerPopup = true;
    },
    callSignersDetails(payload: any): void {
      this.selectedUsers = payload.map(
        ({
          order = 0,
          id = "",
          userId = "",
          profilePicture = "",
          firstName = "",
          lastName = "",
          email = "",
          companyName = "",
          accessCode = "",
          placeholder = "",
          isContact = false,
        }) =>
          new ContractSigner(
            order,
            id || userId,
            profilePicture,
            firstName,
            lastName,
            email,
            companyName,
            placeholder,
            accessCode,
            isContact
          )
      );
      this.isShowAddSignerPopup = false;
      this.isShowSignerDetailPopup = true;
    },
    onAddSigners(payload: any): void {
      this.signers = payload;
      this.isShowSignerDetailPopup = false;
    },
    handleDragDone(payload: any): void {
      this.signers = payload;
    },
    editSigner(payload: number): void {
      if ((this.signers[payload] as any).email) {
        this.signerEditIndex = payload;
      } else {
        this.placeholderSignerIndex = payload;
        this.isShowSelectPlaceholderPopup = true;
      }
    },
    setSingleSignerDetail(payload: any): void {
      if (this.signerEditIndex >= 0)
        this.signers[this.signerEditIndex] = { ...payload } as never;
      this.signerEditIndex = -1;
    },
    editPlaceholderInfo(payload: any): void {
      this.signers[this.placeholderSignerIndex] = payload as never;
      this.placeholderSignerIndex = -1;
      this.isShowSelectPlaceholderPopup = false;
    },
    removeSelectedUser(index: number) {
      if (this.signers.length > 0) this.signers.splice(index, 1);
    },
  },
});
