import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20dd289b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "error-message" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "input-row" }
const _hoisted_5 = { class: "input-row mt-2" }
const _hoisted_6 = { class: "input-area" }
const _hoisted_7 = { class: "input-label text-left" }
const _hoisted_8 = { class: "input-area" }
const _hoisted_9 = { class: "input-label text-left" }
const _hoisted_10 = { class: "section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!
  const _component_PlaceholderUserTable = _resolveComponent("PlaceholderUserTable")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    onOnHide: _ctx.hidePopup,
    onOnClickOutsidePopup: _ctx.hidePopup
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PlaceholderUserTable, {
          items: _ctx.users,
          "selected-items": _ctx.signers,
          label: _ctx.$t('contractSigner.signerEmailLabel'),
          "is-required": true,
          onOnUpdate: _ctx.onSelectUser,
          onOnInput: _ctx.userSearch
        }, {
          default: _withCtx((slotProp) => [
            (slotProp.error)
              ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                  key: 0,
                  class: "error-tooltip"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(slotProp.error), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["items", "selected-items", "label", "onOnUpdate", "onOnInput"]),
        _createElementVNode("p", {
          innerHTML: _ctx.$t('contractSigner.signerNameLabel'),
          class: "input-label text-left mt-2"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ValidationTextField, {
            value: _ctx.selectedUser.lastName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUser.lastName) = $event)),
            placeholder: _ctx.$t('common.lastnameHint'),
            class: "input-area",
            "keep-error-space": false,
            name: "lastName",
            rules: "name|required",
            message: {
            error_required: _ctx.$t('errors.required'),
            error_format_name: _ctx.$t('errors.formatName'),
          }
          }, null, 8, ["value", "placeholder", "message"]),
          _createVNode(_component_ValidationTextField, {
            value: _ctx.selectedUser.firstName,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUser.firstName) = $event)),
            placeholder: _ctx.$t('common.firstnameHint'),
            class: "input-area",
            "keep-error-space": false,
            name: "firstName",
            rules: "name|required",
            message: {
            error_required: _ctx.$t('errors.required'),
            error_format_name: _ctx.$t('errors.formatName'),
          }
          }, null, 8, ["value", "placeholder", "message"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("contractSigner.companyNameLabel")), 1),
            _createVNode(_component_validation_text_field, {
              value: _ctx.selectedUser.companyName,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedUser.companyName) = $event)),
              placeholder: _ctx.$t('contractSigner.companyHint'),
              class: "w-full",
              name: "companyName",
              "keep-error-space": false
            }, null, 8, ["value", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("contractSigner.passwordLabel")), 1),
            _createVNode(_component_validation_text_field, {
              placeholder: '12345',
              class: "w-full",
              name: "accessCode",
              value: _ctx.selectedUser.accessCode,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedUser.accessCode) = $event)),
              "keep-error-space": false
            }, null, 8, ["value"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_flat_button, {
          onOnClick: _ctx.confirmChange,
          class: "confirm-btn",
          text: _ctx.$t('contractSigner.editPlaceholderButton'),
          enabled: _ctx.isEmail(_ctx.selectedUser.email)
        }, null, 8, ["onOnClick", "text", "enabled"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOnHide", "onOnClickOutsidePopup"]))
}