
import { defineComponent, reactive, toRefs, ref, onMounted, inject } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import PlaceholderUserTable from "@/components/atomics/PlaceholderUserTable.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { RepositoryFactory, ContractRepository } from "@/lib/https";
import Popup from "@/components/popups/Popup.vue";

import closeIcon from "@/assets/icons/close.svg";
import { useForm } from "vee-validate";
import { isEmptyObject } from "@/lib/utility/common";
import { ContractSigner } from "@/models/contracts/ContractSigner";
import { isEmail } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "SignerPlaceholderPopup",
  components: {
    FlatButton,
    PlaceholderUserTable,
    ValidationTextField,
    Popup,
    MenuOptionDropdown,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    signers: { type: Array, default: [] as Array<ContractSigner> },
  },
  setup(props, { emit }) {
    const state = reactive({
      closeIcon: closeIcon,
      selectedUser: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        accessCode: "",
        profilePicture: "",
      },
      users: ref([]),
    });
    const isMobile = inject("isMobile");
    const { submitForm, errors } = useForm();

    let delay: ReturnType<typeof setTimeout>;

    const { getSearchUsersInAllCompanies } = contractMethods();

    /**
     * Enter search
     */
    const userSearch = (searchKeyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const userPayload = await getSearchUsersInAllCompanies(
          1,
          100,
          searchKeyword
        );
        state.users = userPayload.data;
      }, 300);
      state.selectedUser.email = searchKeyword;
    };

    const confirmChange = async () => {
      submitForm().then(() => {
        if (!isEmptyObject(errors.value)) return;
        const user = {
          id: state.selectedUser.id ?? "",
          userId: state.selectedUser.id || "",
          firstName: state.selectedUser.firstName,
          lastName: state.selectedUser.lastName,
          email: state.selectedUser.email,
          companyName: state.selectedUser.companyName ?? "",
          accessCode: state.selectedUser.accessCode ?? "",
          profilePicture: state.selectedUser.profilePicture,
        };
        emit("onChange", user);
      });
    };

    onMounted(async () => {
      const userPayload = await getSearchUsersInAllCompanies(1, 100, "");
      state.users = userPayload.data;
    });

    return { ...toRefs(state), isMobile, userSearch, confirmChange, isEmail };
  },

  methods: {
    clickOutPopup(e: any): void {
      if (e.target.className === "popup-wrapper") {
        this.$emit("onClickOutsidePopup");
      }
    },

    hidePopup(): void {
      this.$emit("onHide");
    },

    onSelectUser(user: any): void {
      this.selectedUser = user;
    },

    // Button 編集する click
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

//Create method from API
const contractMethods = () => {
  const { getSearchUsersInAllCompanies } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  return {
    getSearchUsersInAllCompanies,
  };
};
