import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50f0e560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-row" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "section-top" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "group-list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "group-row-leading" }
const _hoisted_8 = { class: "username" }
const _hoisted_9 = {
  key: 0,
  class: "email"
}
const _hoisted_10 = { class: "company-tag" }
const _hoisted_11 = {
  key: 0,
  class: "row-divider mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        innerHTML: _ctx.label,
        class: "input-label"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      ref: "group",
      class: _normalizeClass(["group-body", { focused: _ctx.inputFocused }]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputFocused = true))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ValidationTextField, {
            class: "input",
            value: _ctx.email,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            placeholder: _ctx.$t('contractSigner.editPlaceholder'),
            name: "email",
            rules: "required",
            message: {
            error_required: _ctx.$t('errors.required'),
            error_format_email: _ctx.$t('errors.formatEmail'),
          },
            "display-error-message": false,
            onOnInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onInput', $event))),
            onOnError: _cache[2] || (_cache[2] = ($event: any) => (_ctx.error = $event)),
            onKeyup: _withKeys(_ctx.createTag, ["enter"])
          }, null, 8, ["value", "placeholder", "message", "onKeyup"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["group-row", { selected: _ctx.isSelectedUser(item) }]),
            onClick: ($event: any) => (!_ctx.isSelectedUser(item) ? _ctx.selectUser(item) : null)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([{ selected: _ctx.isSelectedUser(item) }, "single-row"])
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AvatarCircle, {
                  avatar: 
                item.profilePicture
                  ? _ctx.getFilePathFromUrl(item.profilePicture)
                  : require('icons/default-avatar.svg')
              ,
                  alt: "avatar",
                  class: "icon"
                }, null, 8, ["avatar"]),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(item.lastName + " " + item.firstName), 1),
                  (item.email)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(item.email), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(item.companyName), 1)
            ], 2),
            (index != _ctx.items.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : _createCommentVNode("", true)
          ], 10, _hoisted_6))
        }), 128))
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "default", { error: _ctx.error }, undefined, true)
  ], 64))
}