
import { defineComponent, reactive, toRefs } from "vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import ValidationTextField from "./ValidationTextField.vue";

import { isEmail } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "PlaceholderUserTable",
  components: {
    ValidationTextField,
    AvatarCircle,
  },
  props: {
    items: { type: Array, default: [] as never[] },
    label: { type: String, default: "" },
    isRequired: { type: Boolean, default: false },
    selectedItems: { type: Array, default: [] as never[] },
  },
  emits: ["onUpdate", "onInput"],
  setup(props, { emit }) {
    const state = reactive({
      email: "",
      error: "",
      inputFocused: false,
    });

    const selectUser = (user: any) => {
      state.email = user.email;
      emit("onUpdate", user);
    };

    const isSelectedUser = (user: any) => {
      return state.email === user.email;
    };

    return {
      ...toRefs(state),
      selectUser,
      isSelectedUser,
      getFilePathFromUrl,
      isEmail,
    };
  },
  methods: {
    clickOutFocusedBox(e: MouseEvent) {
      if (!(this.$refs.group as any)?.contains(e.target)) {
        this.inputFocused = false;
      }
    },
    createTag(): void {
      if (isEmail(this.email)) {
        let item;
        const user = this.items.find((item: any) => item.email === this.email);
        if (user) {
          item = { ...(user as any) };
          this.selectUser(item);
          this.$emit("onInput", "");
        }
      }
    },
  },
});
